import React from "react";
import Navbar from "./navbar";
import Sidebar from "./sidebar";

export default function Template({ children }) {
  return (
    <div>
      <main className="flex w-full ">
        <Sidebar />
        <Navbar />
        <section className="w-full bg-gray-100 bg-fixed  2xl:px-8 py-1">
          {children}
        </section>
      </main>
    </div>
  );
}
