import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import Template from "../components/template";
import ProductsByID from "./ProductsById";
import axios from "axios";
import moment from "moment";
import { AuthContext } from "../auth/authHandler";
import Pagination from "../components/pagination";
import Table from "../components/table";
import Loading from "../components/Loading";
import { useGetAllProducts } from "./ProductsService";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export default function Products() {
  const [pagination, setPagination] = useState(1);
  function getCountry() {
    const userString = sessionStorage.getItem("country");
    const userCountry = JSON.parse(userString);
    return userCountry;
  }
  getCountry();

  const [products, metaData, dataLoading, error] = useGetAllProducts(
    getCountry().id,
    pagination
  );

  return (
    <Template>
      <div className="w-full mt-10  p-4 text-md">
        <h1 className="text-center text-4xl  text-green-700">Products</h1>
        <div className="w-full flex justify-end mb-8 px-12">
          <Link
            to="/products/add"
            className="flex rounded bg-green-800 px-4  py-2 text-white justify-end mr-2"
          >
            <svg
              className="w-6 h-6 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            Add Product
          </Link>
        </div>
        {dataLoading ? (
          <Loading text={"Products"} />
        ) : products && products.length > 0 ? (
          <Table>
            <div className="align-middle inline-block min-w-full shadow overflow-hidden bg-white shadow-dashboard px-8 pt-3 rounded-bl-lg rounded-br-lg">
              <div className="table-height overflow-y-scroll">
                <table className="min-w-full">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-green-600 tracking-wider">
                        ID
                      </th>
                      <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                        Product Name
                      </th>
                      <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                        Manufacturer
                      </th>
                      <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                        Product Category
                      </th>
                      <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                        Weight
                      </th>
                      <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                        Added On
                      </th>
                      <th className="px-6 py-3 border-b-2 border-gray-300"></th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {products &&
                      products.map((product, index) => {
                        return (
                          <tr>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                              <div className="flex items-center">
                                <div>
                                  <div className="text-sm leading-5 text-gray-800">
                                    {(pagination - 1) * 15 + index + 1}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                              <div className="text-sm leading-5 text-green-900">
                                {product.name ? product.name : "not available"}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b text-green-900 border-gray-500 text-sm leading-5">
                              {product.made_by
                                ? product.made_by.name
                                : "not available"}
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b text-green-900 border-gray-500 text-sm leading-5">
                              {product.supported_products.category.name
                                ? product.supported_products.category.name
                                : "not available"}
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b text-green-900 border-gray-500 text-sm leading-5">
                              {product.packaging.weight &&
                              product.packaging.unit.short_unit
                                ? `${product.packaging.weight} ${product.packaging.unit.short_unit}`
                                : "not available"}
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500 text-green-900 text-sm leading-5">
                              {moment(product.created_at).format("MM/DD/YYYY")}
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-500 text-sm leading-5">
                              <div className=" border-green-600 border text-green-600 rounded transition duration-300 hover:bg-green-600 hover:text-white focus:outline-none">
                                <Link
                                  to={{
                                    pathname: `/products/product/${product.name}`,
                                    state: product,
                                  }}
                                  className="w-full text-center justify-center flex px-3 py-2 h-full"
                                >
                                  View Details
                                </Link>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <Pagination metaData={metaData} setPagination={setPagination} />
            </div>
          </Table>
        ) : (
          <p className="text-center text-green-900 text-sm leading-5">
            No products added, please add a product
          </p>
        )}
      </div>
    </Template>
  );
}
