import axios from "axios";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import image from "../../assets/AFAP-Logo-white 1.png";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

function ForgottenPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const history = useHistory();

  const onSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios
      .post(
        "/forgot-password",
        {
          email,
        },
        {
          headers: {
            "Content-Type": "application/json",
            // Accept: "application/json",
          },
        }
      )
      .then((response) => {
        setShowModal(true);
        setIsSubmitting(false);
      })
      .catch((error) => {
        setIsSubmitting(false);
        if (error.response.status >= 500) {
          setError("Well, this unexpected. We are sorry");
        } else {
          setError(error.response.data.message);
        }
      });
  };

  return (
    <div className="flex items-center min-h-screen bg-green-600 dark:bg-gray-900">
      <div className="container mx-auto">
        <div className="flex justify-center items-center ">
          <div className=" p-4 rounded-lg bg-white">
            <img alt="" className=" text-center" src={image} />
          </div>
        </div>
        <div className="max-w-lg mx-auto rounded-lg border shadow p-4 bg-white my-10">
          <div className="text-center">
            <h1 className="my-3 text-3xl font-semibold text-gray-700 dark:text-gray-200">
              Forgotten Password
            </h1>
            <p className="text-gray-500 dark:text-gray-400">
              If you forgot your password, don't worry happens to the best of
              us.
            </p>
            {error && (
              <p className="text-red-500 dark:text-red-400 mt-2">{error}</p>
            )}
          </div>
          <div className="m-7">
            {showModal ? (
              <>
                <div
                  className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                  // onClick={() => setShowModal(false)}
                >
                  <div className="relative w-auto my-6  mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                      {/*header*/}
                      <div className="flex items-start justify-between p-5  rounded-t">
                        <h3 className="text-3xl font-semibold px-6 pt-10">
                          Please check your email for the link
                        </h3>
                      </div>
                      {/*body*/}
                      <div className="w-full flex justify-center">
                        <svg
                          className="w-64 h-64 text-green-600 flex text-center"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </div>
                      {/*footer*/}
                      <div className="flex items-center justify-end p-6  rounded-b">
                        <button
                          className="bg-green-600 w-full text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                          style={{ transition: "all .15s ease" }}
                          onClick={() => history.push("/")}
                        >
                          Okay
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </>
            ) : null}
            <form onSubmit={onSubmit} autoComplete="off">
              <div className="mb-6">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  placeholder="you@company.com"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-100 focus:border-green-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                />
              </div>
              <div className="mb-4">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full px-3 py-4 text-white bg-green-500 rounded-md focus:bg-green-800 focus:outline-none"
                >
                  {isSubmitting ? "processing..." : "Reset Password"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgottenPassword;
