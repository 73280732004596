import axios from "axios";
import { useEffect, useState } from "react";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

function ResetPassword({ match }) {
  const [user, setUser] = useState({ password: "", passwordConfirm: "" });
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    getURLParams();
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  function getURLParams() {
    const { token, email } = match.params;
    setEmail(email);
    setToken(token);
  }

  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const onSubmit = () => {
    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios
      .post(
        "/reset-password",
        {
          email,
          token,
          password: user.password,
          password_confirmation: user.passwordConfirm,
        },
        {
          headers: {
            "Content-Type": "application/json",
            // Accept: "application/json",
          },
        }
      )
      .then((response) => {
        setSuccess(true);
      })
      .catch((error) => {
        if (error.response.status >= 500) {
          setError("Well, this unexpected. We are sorry");
        } else {
          setError(error.response.data.message);
        }
      });
  };

  if (!success) {
    return (
      <div className="flex items-center min-h-screen  dark:bg-gray-900">
        <div className="container mx-auto">
          <div className="max-w-md mx-auto my-10">
            <div className="text-center">
              <h1 className="my-3 text-3xl font-semibold text-gray-700 dark:text-gray-200">
                Reset Password
              </h1>
              <p className="text-gray-500 dark:text-gray-400">
                Sign in to access your account
              </p>
              {error && (
                <p className="text-red-500 dark:text-red-400 mt-2">{error}</p>
              )}
            </div>
            <div className="m-7">
              <form>
                <div className="mb-1">
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                  >
                    Email Address
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                    className="w-full h-12 mb-8 placeholder-white justify-center text-white p-4 bg-green-500 rounded-full"
                  />
                </div>
                <div className="mb-1">
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    value={user.password}
                    placeholder="**********"
                    className="w-full h-12 mb-8 placeholder-white justify-center text-white p-4 bg-green-500 rounded-full"
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4">
                  <div className="flex justify-between mb-2">
                    <label
                      htmlFor="password"
                      className="text-sm text-gray-600 dark:text-gray-400"
                    >
                      Confirm Password
                    </label>
                  </div>
                  <input
                    type="password"
                    name="passwordConfirm"
                    id="passwordConfirm"
                    value={user.passwordConfirm}
                    placeholder="*********"
                    className="w-full h-12 mb-8 placeholder-white justify-center text-white p-4 bg-green-500 rounded-full"
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4">
                  <button
                    onClick={onSubmit}
                    type="button"
                    className="w-full bg-yellow-300 h-12 p-4 items-center justify-center  mb-4 rounded-full"
                  >
                    Reset Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex items-center min-h-screen  dark:bg-gray-900">
        <div className="container mx-auto">
          <div className="max-w-md mx-auto my-10">
            <div className="text-center">
              <h1 className="my-3 text-3xl font-semibold text-gray-700 dark:text-gray-200">
                Reset Password
              </h1>
              <p className="text-gray-500 dark:text-gray-400">
                Your Password Has Successfully Been Changed
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
