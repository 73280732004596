import React from "react";
import AuthHandler from "../auth/authHandler";
import Routes from "./Routes";

export const Providers = ({}) => {
  return (
    <AuthHandler>
      <Routes />
    </AuthHandler>
  );
};
