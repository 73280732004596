import React, { useState, useEffect } from "react";

function Search({ setState, placeholder, searchValue, clearSearch }) {
  const [value, setValue] = useState(searchValue);

  const onChangeSearchInput = (e) => {
    setValue(e.target.value);
    setTimeout(() => {
      setState(e.target.value);
    }, 2000);
  };

  return (
    <div className="inline-flex border rounded w-4/12 px-2 lg:px-6 h-12 bg-transparent">
      <div className="flex flex-wrap items-stretch w-full h-full mb-6 relative">
        <div className="flex">
          <span className="flex items-center leading-normal bg-transparent rounded rounded-r-none border border-r-0 border-none lg:px-3 py-2 whitespace-no-wrap text-grey-dark text-sm">
            <svg
              width="18"
              height="18"
              className="w-4 lg:w-auto"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.11086 15.2217C12.0381 15.2217 15.2217 12.0381 15.2217 8.11086C15.2217 4.18364 12.0381 1 8.11086 1C4.18364 1 1 4.18364 1 8.11086C1 12.0381 4.18364 15.2217 8.11086 15.2217Z"
                stroke="#455A64"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.9993 16.9993L13.1328 13.1328"
                stroke="#455A64"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </div>
        <input
          type="text"
          className="flex-shrink flex-grow flex-auto leading-normal tracking-wide w-px  border border-none border-l-0 rounded rounded-l-none px-3 relative focus:outline-none text-xxs xl:text-xs lg:text-base text-gray-500 font-thin"
          placeholder={placeholder}
          onChange={(e) => onChangeSearchInput(e)}
          value={value}
        />
        {value ? (
          <i
            className="fas fa-times-circle text-center my-auto text-gray-500 hover:text-green-700"
            onClick={() => clearSearch()}
          ></i>
        ) : null}
      </div>
    </div>
  );
}

function Dropdown({ children }) {
  return (
    <div className="relative justify-center items-center pl-2 flex border rounded focus:outline-none focus:bg-white focus:border-gray-500">
      <svg
        className="w-6 h-6 text-gray-600 "
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
        />
      </svg>
      {children}
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        <svg
          className="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </div>
    </div>
  );
}

function Header({ children, searchInput, limitOnChange }) {
  return (
    <div className="align-middle rounded-tl-lg rounded-tr-lg inline-block w-full py-4 overflow-hidden bg-white shadow-lg px-12">
      <div className="flex justify-between">
        <div className="flex flex-row mb-1 sm:mb-0">
          {limitOnChange && (
            <div className="relative justify-center items-center pl-2 flex border rounded focus:outline-none focus:bg-white focus:border-gray-500">
              <svg
                className="w-6 h-6 text-gray-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 10h16M4 14h16M4 18h16"
                />
              </svg>
              <svg
                className="w-6 h-6 text-gray-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M8 9l4-4 4 4m0 6l-4 4-4-4"
                />
              </svg>
              <select
                onChange={limitOnChange}
                className="appearance-none h-full  block w-full bg-white  text-gray-700 py-2 px-4 pr-8 leading-tight "
              >
                <option value="15">15</option>
                <option value="25">25</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          )}
          {children ? children : null}
        </div>
        {searchInput ? searchInput : null}
      </div>
    </div>
  );
}

function Table({ children }) {
  return (
    <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 pr-10 lg:px-8">
      {children}
    </div>
  );
}

Table.Header = Header;
Table.Dropdown = Dropdown;
Table.Search = Search;

export default Table;
