import { useContext, useEffect, useState } from "react";
import { Route, Redirect, useHistory, Switch } from "react-router-dom";
import Login from "../auth/pages/Login";
import { AuthContext } from "../auth/authHandler";
import { history } from "../helpers/history";
import Home from "../pages/Home";
import ResetPassword from "../auth/pages/ResetPassword";
import Orders from "../pages/Orders";
import CompanyRequest from "../CompanyRequest/CompanyRequest";
import Products from "../Products/Products";
import Sales from "../Sales/Sales";
import UserManagement from "../UserManagement/UserManagement";
import Companies from "../CompanyManagement/Companies";
import AddProduct from "../Products/AddProduct";
import AddManufacturer from "../Products/AddManufacturer";
import StockAvailability from "../Reports/Stock Availability/StockAvailability";
import SalesVolume from "../Reports/SalesVolume/SalesVolume";
import AddClientUser from "../auth/pages/AddClientUser";
import ClientMaps from "../ClientMap/ClientMaps";
import HelpAndSupport from "../pages/HelpAndSupport";
import CountryConfig from "../Settings/CountryConfiguration/CountryConfig";
import AddCountry from "../Settings/CountryConfiguration/AddCountry";
import AddRegion from "../Settings/CountryConfiguration/AddRegion";
import SalesValue from "../Reports/SalesVolume/SalesValue";
import CreateAnnouncement from "../Announcements/CreateAnnouncement";
import EditCountry from "../Settings/CountryConfiguration/EditCountry";
import CompanyByID from "../CompanyManagement/CompaniesById";
import StoreByID from "../CompanyManagement/StoreById";
import CompanyRequestByID from "../CompanyRequest/CompanyRequestByID";
import ProductsByID from "../Products/ProductsById";
import SalesVolumePerProduct from "../Reports/SalesVolume/SalesVolumePerProduct";
import ShowStockAvailabilitySelected from "../Reports/Stock Availability/ShowStockAvailabilitySelected";
import SendUserInvite from "../UserManagement/SendUserInvite";
import AddPlatformCompanyUser from "../auth/pages/AddPlatformCompanyUser";
import ViewCountry from "../Settings/CountryConfiguration/ViewCountry";
import PageNotFound from "../pages/404Page";
import ForgottenPassword from "../auth/pages/ForgottenPassword";

function Routes() {
  const { user, setUser, country, setCountry } = useContext(AuthContext);
  // const [loaded, setLoaded] = useState({});

  // const historyNav = useHistory();

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    getCountry();
  }, []);

  function getUser() {
    const userString = sessionStorage.getItem("user");
    const userCred = JSON.parse(userString);
    setUser(userCred);
    return userCred;
  }

  function getCountry() {
    const userString = sessionStorage.getItem("country");
    const userCountry = JSON.parse(userString);
    setCountry(userCountry);
    return userCountry;
  }

  function PrivateRoute({ children, ...rest }) {
    return <Route {...rest} render={() => (user ? children : <Login />)} />;
  }
  return (
    <div>
      <Switch>
        <Route
          history={history}
          exact
          path="/reset/password/:token/:email"
          component={ResetPassword}
        />
        <PrivateRoute
          history={history}
          path="/user/maps"
          exact
          // component={ClientMaps}
        >
          <ClientMaps />
        </PrivateRoute>
        <Route
          history={history}
          path="/user/helpandsupport"
          component={HelpAndSupport}
        />
        <Route
          history={history}
          path="/forgotten_password"
          component={ForgottenPassword}
        />
        <Route
          history={history}
          path="/add/user/:email/:company_id/:branch_id/:role_id/:token"
          component={AddClientUser}
        />
        <Route
          history={history}
          path="/company/platform-company/add/user/:email/:company_id/:role_id/:token"
          component={AddPlatformCompanyUser}
        />
        <Route
          history={history}
          path="/company/manufacturer/add/user/:email/:company_id/:role_id/:token"
          // component={AddPlatformCompanyUser}
        />
        <PrivateRoute
          exact
          history={history}
          path="/"
          // component={Home}
        >
          <Home />
        </PrivateRoute>
        <PrivateRoute
          history={history}
          exact
          path="/user_management"
          // component={Home}
        >
          <UserManagement />
        </PrivateRoute>
        <PrivateRoute
          history={history}
          path="/user_management/send_invite"
          // component={Home}
        >
          <SendUserInvite />
        </PrivateRoute>
        <PrivateRoute
          history={history}
          exact
          path="/settings/country_configuration"
          // component={Home}
        >
          <CountryConfig />
        </PrivateRoute>
        <PrivateRoute
          history={history}
          path="/settings/country_configuration/add"
        >
          <AddCountry />
        </PrivateRoute>
        <PrivateRoute
          history={history}
          path="/settings/country_configuration/edit/:country"
        >
          <EditCountry />
        </PrivateRoute>
        <PrivateRoute
          history={history}
          path="/settings/country_configuration/add_regions/:id?"
        >
          <AddRegion />
        </PrivateRoute>
        <PrivateRoute
          history={history}
          path="/settings/country_configuration/:country/regions"
        >
          <ViewCountry />
        </PrivateRoute>

        <PrivateRoute
          exact
          history={history}
          path="/reports/stock_availability/:id?"
        >
          <StockAvailability />
        </PrivateRoute>
        <PrivateRoute
          history={history}
          path="/reports/stock_availability/branch/:branch_name"
        >
          <ShowStockAvailabilitySelected />
        </PrivateRoute>

        <PrivateRoute
          history={history}
          exact
          path="/reports/sale_volume"
          // component={Home}
        >
          <SalesVolume />
        </PrivateRoute>
        <PrivateRoute
          history={history}
          path="/reports/sale_volume/:product_name"
          // component={Home}
        >
          <SalesVolumePerProduct />
        </PrivateRoute>
        <PrivateRoute
          history={history}
          path="/reports/sale_value"
          // component={Home}
        >
          <SalesValue />
        </PrivateRoute>
        <PrivateRoute
          exact
          history={history}
          path="/company_requests"
          // component={Home}
        >
          <CompanyRequest />
        </PrivateRoute>
        <PrivateRoute
          history={history}
          path="/company_requests/:company_name"
          // component={Home}
        >
          <CompanyRequestByID />
        </PrivateRoute>
        <PrivateRoute
          history={history}
          path="/comapnies/manufacturer/add"
          // component={Home}
        >
          <AddManufacturer />
        </PrivateRoute>
        <PrivateRoute
          exact
          history={history}
          path="/companies/:company_type?"
          // component={Home}
        >
          <Companies />
        </PrivateRoute>
        <PrivateRoute
          exact
          history={history}
          path="/company/:company_name"
          // component={Home}
        >
          <CompanyByID />
        </PrivateRoute>
        <PrivateRoute
          history={history}
          path="/company/:company_name/branch/:branch_name"
          // component={Home}
        >
          <StoreByID />
        </PrivateRoute>

        <PrivateRoute
          history={history}
          path="/products"
          exact
          // component={Home}
        >
          <Products />
        </PrivateRoute>
        <PrivateRoute history={history} path="/products/product/:product_name">
          <ProductsByID />
        </PrivateRoute>
        <PrivateRoute
          history={history}
          path="/products/add"
          // component={Home}
        >
          <AddProduct />
        </PrivateRoute>
        <PrivateRoute
          exact
          history={history}
          path="/sales"
          // component={Home}
        >
          <Sales />
        </PrivateRoute>
        <PrivateRoute
          exact
          history={history}
          path="/announcements"
          // component={Home}
        >
          <CreateAnnouncement />
        </PrivateRoute>

        {/* <Route history={history} path="/sign/up" component={SignUp}></Route> */}

        {/* {loaded ? historyNav.push("/dashboard") : null} */}
        <Route history={history} path="*" component={PageNotFound} />
      </Switch>
    </div>
  );
}

export default Routes;
