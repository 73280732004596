import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

import { Formik, FieldArray } from "formik";

export default function AddRegion({ country }) {
  const [loaded, setLoaded] = useState({});
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const history = useHistory();

  useEffect(() => {
    getUser();
  }, []);

  function getUser() {
    const userString = sessionStorage.getItem("user");
    const userCred = JSON.parse(userString);
    setLoaded(userCred);
    return userCred;
  }

  axios.defaults.headers.common["Authorization"] = `Bearer ${loaded.token}`;

  const onSubmit = async (regions) => {
    try {
      let response = await axios.post(
        "admin/countries/regions",
        {
          country_id: country.id,
          regions: regions,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      setShowModal(true);
    } catch (error) {
      if (error?.response?.status >= 500) {
        setError("Well, this unexpected. We are sorry");
      } else {
        setError(error?.response?.data?.message);
      }
    }
  };
  return (
    <div className="mt-5 md:mt-0 md:col-span-2 mb-2">
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            // onClick={() => setShowModal(false)}
          >
            <div className="relative w-auto my-6  mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5  rounded-t">
                  <h3 className="text-3xl font-semibold px-6 pt-10">
                    Successfully Added Regions
                  </h3>
                </div>
                {/*body*/}
                <div className="w-full flex justify-center">
                  <svg
                    className="w-64 h-64 text-green-600 flex text-center"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  {/* <h1 className="text-green-600 text-2xl text-center">
                        Approved
                      </h1> */}
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6  rounded-b">
                  <Link
                    className="bg-green-600 w-full text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                    style={{ transition: "all .15s ease" }}
                    to={{
                      pathname: `/settings/country_configuration/${country.name}/regions`,
                      state: country,
                    }}
                  >
                    Okay
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {/* <div className="shadow overflow-hidden sm:rounded-md">
        <div className="px-4 py-5 bg-white sm:p-6"> */}
      {error && <p className="text-red-500 dark:text-red-400 mt-2">{error}</p>}
      <Formik
        enableReinitialize="true"
        initialValues={{
          regions: [],
        }}
        onSubmit={(values) => {
          onSubmit(values.regions);
        }}
      >
        {(props) => (
          <>
            <FieldArray name="regions">
              {({ insert, remove, push }) => (
                <>
                  <div className="w-full flex justify-end mb-4  cursor-pointer">
                    <div
                      className="flex rounded bg-green-800 px-4  py-2 text-white justify-end"
                      onClick={() => push()}
                    >
                      <svg
                        className="w-6 h-6 mr-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      Add Region
                    </div>
                  </div>
                  {props.values.regions.length > 0 &&
                    props.values.regions.map((region, index) => (
                      <div className="grid grid-cols-6 gap-6 mb-4">
                        {/* <label
                                  htmlFor="region"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Region
                                </label> */}
                        <div
                          className="col-span-6 flex items-center"
                          key={index}
                        >
                          <p className="block text-sm font-medium text-gray-700 mr-2">
                            {index + 1}
                          </p>
                          <input
                            type="text"
                            name="region"
                            id="region"
                            value={props.values.regions[index]}
                            onChange={props.handleChange(`regions.${index}`)}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full  sm:text-sm border px-2 py-2 border-green-600 rounded"
                          />

                          <div
                            className=" ml-2 text-red-800  cursor-pointer"
                            onClick={() => remove(index)}
                          >
                            <svg
                              className="w-6 h-6"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                stroke-width="1.5"
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              )}
            </FieldArray>

            {props.values.regions.length == 0 ? null : (
              <div className=" flex justify-between text-right ">
                {/* <Link
                        to="/products"
                        className="inline-flex justify-center rounded bg-red-800 px-4   py-2 text-white  mr-2"
                      >
                        Cancel
                      </Link> */}
                <button
                  onClick={() => props.setFieldValue("regions", [])}
                  className="inline-flex justify-center rounded bg-red-800 px-4   py-2 text-white  mr-2"
                >
                  Cancel
                </button>
                <button
                  onClick={props.handleSubmit}
                  className="inline-flex justify-center rounded bg-green-800 px-4   py-2 text-white  mr-2"
                >
                  Save
                </button>
              </div>
            )}
          </>
        )}
      </Formik>
    </div>
    //   </div>
    // </div>
  );
}
