import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../auth/authHandler";
import axios from "axios";
import Select from "react-select";
import { Link, useLocation } from "react-router-dom";
import Template from "../../components/template";
import AsyncSelect from "react-select/async";
import { useGetCompanies } from "../../CompanyManagement/CompanyService";

export default function ShowStockAvailabilitySelected() {
  const location = useLocation();
  const store = location.state.store;
  const allStore = location.state.allStores;

  const { user, setUser } = useContext(AuthContext);
  const [products, setProducts] = useState([]);
  const [productsAll, setProductsAll] = useState(null);
  const [selectInput, setSelectInput] = useState("");
  const [selectedStore, setSelectedStore] = useState({
    value: store.id,
    label: store.name,
  });
  // const [companyList, setCompanyList] = useState([]);
  const [company, setCompany] = useState([]);
  const [companyName, setCompanyName] = useState(store.company.name);

  const [storeID, setStoreID] = useState(store.id);

  const [companiesData, dataLoading, error] = useGetCompanies(
    getCountry(),
    "",
    "",
    "",
    false
  );

  const selectStyle = {
    input: (styles) => ({
      ...styles,
      borderRadius: 3,
      borderColor: "#047857",
      outlineStyle: "none",
      outlineColor: "transparent",
    }),
    option: (styles, { isSelected, isFocused }) => {
      const color = "rgba(4, 120, 87,0.3)";
      return {
        ...styles,
        height: 40,
        backgroundColor: isSelected ? color : isFocused ? color : undefined,
        borderColor: "#047857",
        borderRadius: 3,
      };
    },
    menuList: (styles) => ({
      ...styles,
      height: 200,
    }),
    control: (styles, { isSelected, isFocused }) => ({
      ...styles,
      outlineStyle: "none",
      outlineColor: "transparent",
      borderColor: isSelected ? "#047857" : isFocused ? "#047857" : "#047857",
      borderWidth: isSelected ? 2 : isFocused ? 2 : 1,
      ":active": {
        ...styles[":active"],
        borderColor: "#047857",
      },
      ":hover": {
        ...styles[":hover"],
        borderColor: "#047857",
      },
      ":focus": {
        ...styles[":focus"],
        borderColor: "#047857",
      },
    }),

    multiValueLabel: (styles) => ({
      ...styles,

      backgroundColor: "#047857",
      color: "white",
    }),
    multiValueRemove: (styles) => ({
      ...styles,

      backgroundColor: "#047857",
      color: "white",
    }),
  };

  useEffect(() => {
    getCompany();
  }, []);

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;

    axios
      .get(`/branches/${storeID}/products`)
      .then((response) => {
        setProducts(response.data.data);
        setProductsAll(response.data);
      })
      .catch((error) => {
        console.log(error?.response);
      });
  }, [user, storeID]);

  function getCountry() {
    const userString = sessionStorage.getItem("country");
    const userCountry = JSON.parse(userString);

    return userCountry;
  }

  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    setSelectInput(inputValue);
    return inputValue;
  };

  const getCompany = async () => {
    if (user) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;

      let newArray = [];

      companiesData.map((data) => {
        let obj = {
          value: data.id,
          label: data.name,
          branches: data?.branches,
        };

        newArray.push(obj);
      });
      if (selectInput.length === 0) {
        return newArray;
      } else {
        return newArray.filter((i) =>
          i.label.toLowerCase().includes(selectInput.toLowerCase())
        );
      }
    }
  };

  const getBranches = () => {
    let branches = [];

    if (company.branches && company.branches.length !== 0) {
      company.branches.map((branch) => {
        if (branch) {
          let obj = {
            value: branch.id,
            label: branch.name,
          };
          branches.push(obj);
        }
      });
    }

    return branches;
  };

  const calculateTons = (weight, quanity, unit) => {
    if (unit == "Kilograms") {
      const ton = weight * quanity * 0.001102311;
      return parseFloat(ton.toFixed(2));
    } else if (unit == "Grams") {
      const ton = weight * 0.001 * quanity * 0.001102311;
      return parseFloat(ton.toFixed(4));
    } else if (unit == "Milligrams") {
      const ton = weight * 0.000001 * quanity * 0.001102311;
      return parseFloat(ton.toFixed(5));
    } else return "-";
  };

  return (
    <Template>
      <div className="w-full mt-10  p-4 text-md">
        <h1 className="text-center text-4xl mb-8 text-green-700">
          Stock Availability
        </h1>
        <div className="w-full flex justify-start mb-8 px-12">
          <Link
            className="flex rounded bg-green-800 px-4 py-2 text-white "
            to="/reports/stock_availability/"
          >
            <svg
              className="w-6 h-6 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M7 16l-4-4m0 0l4-4m-4 4h18"
              />
            </svg>
            Back
          </Link>
        </div>
        <div className=" px-10 ">
          <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 pr-10 lg:px-8">
            <div className="col-span-6 ">
              <div className="text-sm leading-5 text-green-900">
                <h1 className="text-center text-2xl mb-8 text-green-700">
                  {companyName}
                </h1>
              </div>
              <>
                <div className="mb-1 col-span-3">
                  <label
                    htmlFor="company"
                    className="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                  >
                    Company
                  </label>
                  <Select
                    // key={companyType.toString()}
                    styles={selectStyle}
                    name="company"
                    value={company ? company : null}
                    onChange={(values) => {
                      setCompany(values);
                    }}
                    cacheOptions={false}
                    defaultOptions
                    options={companiesData}
                    onInputChange={handleInputChange}
                  />
                </div>
                {/* <p>{JSON.stringify(company)}</p> */}
                <div className="mb-1 col-span-3">
                  <label
                    htmlFor="branches"
                    className="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                  >
                    Branches
                  </label>
                  <Select
                    styles={selectStyle}
                    cacheOptions
                    defaultOptions
                    options={getBranches()}
                    value={selectedStore ? selectedStore : null}
                    onChange={(values) => {
                      setSelectedStore(values);
                      setStoreID(values.value);
                      setCompanyName(company.label);
                    }}
                  />
                </div>
              </>
              {/* <div className="mb-4">
                <label
                  htmlFor="country"
                  className="block  font-medium text-gray-700"
                >
                  Select Store
                </label>

                <select
                  id="store"
                  name="store"
                  onChange={(e) => {
                    setStoreID(e.target.value);
                  }}
                  value={storeID}
                  // autocomplete="category"
                  className="mt-1 block w-full border py-2 border-green-600  px-3 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option>Select a value</option>
                  {allStore &&
                    allStore.map((store) => {
                      return (
                        <>
                          <option value={store.id}>{store.name}</option>
                        </>
                      );
                    })}
                </select>
              </div> */}
            </div>

            <div className="align-middle rounded-tl-lg rounded-tr-lg inline-block w-full py-4 overflow-hidden bg-white shadow-lg px-12">
              {/* <div className="flex justify-between">
              <div className="flex flex-row mb-1 sm:mb-0">
                <div className="relative justify-center items-center pl-2 flex border rounded focus:outline-none focus:bg-white focus:border-gray-500">
                  <svg
                    className="w-6 h-6 text-gray-600"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 6h16M4 10h16M4 14h16M4 18h16"
                    />
                  </svg>
                  <svg
                    className="w-6 h-6 text-gray-600"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M8 9l4-4 4 4m0 6l-4 4-4-4"
                    />
                  </svg>
                  <select className="appearance-none h-full  block  w-full bg-white  text-gray-700 py-2 px-4 pr-8 leading-tight ">
                    <option>5</option>
                    <option>10</option>
                    <option>20</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
                <div className="relative justify-center items-center pl-2 flex border rounded focus:outline-none focus:bg-white focus:border-gray-500">
                  <svg
                    className="w-6 h-6 text-gray-600 "
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                    />
                  </svg>
                  <select className="appearance-none h-full  block  w-full bg-white  text-gray-700 py-2 px-4 pr-8 leading-tight ">
                    <option>All</option>
                    <option>Active</option>
                    <option>Inactive</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="inline-flex border rounded w-4/12 px-2 lg:px-6 h-12 bg-transparent">
                <div className="flex flex-wrap items-stretch w-full h-full mb-6 relative">
                  <div className="flex">
                    <span className="flex items-center leading-normal bg-transparent rounded rounded-r-none border border-r-0 border-none lg:px-3 py-2 whitespace-no-wrap text-grey-dark text-sm">
                      <svg
                        width="18"
                        height="18"
                        className="w-4 lg:w-auto"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.11086 15.2217C12.0381 15.2217 15.2217 12.0381 15.2217 8.11086C15.2217 4.18364 12.0381 1 8.11086 1C4.18364 1 1 4.18364 1 8.11086C1 12.0381 4.18364 15.2217 8.11086 15.2217Z"
                          stroke="#455A64"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16.9993 16.9993L13.1328 13.1328"
                          stroke="#455A64"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="flex-shrink flex-grow flex-auto leading-normal tracking-wide w-px border border-none border-l-0 rounded rounded-l-none px-3 relative focus:outline-none text-xxs xl:text-xs lg:text-base text-gray-500 font-thin"
                    placeholder="Search"
                  />
                </div>
              </div>
            </div> */}
            </div>
            <div className="align-middle inline-block min-w-full shadow overflow-hidden bg-white shadow-dashboard px-8 pt-3 rounded-bl-lg rounded-br-lg">
              <table className="min-w-full">
                <thead className="w-full">
                  <tr>
                    <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-green-600 tracking-wider">
                      ID
                    </th>
                    <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                      Product Name
                    </th>

                    <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                      Product weight (per product)
                    </th>
                    <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                      Total weight (Tons)
                    </th>
                    <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                      Price
                    </th>
                    <th className="px-6 py-3 border-b-2 border-gray-300 text-center text-sm leading-4 text-green-600 tracking-wider">
                      Stock Available
                    </th>
                    <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                      Company Name
                    </th>
                    {/* <th className="px-6 py-3 border-b-2 border-gray-300"></th> */}
                    {/* <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                    Weight
                  </th>
                  <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                    Request At
                  </th> */}
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {products &&
                    products.map((product) => {
                      return (
                        <tr>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                            <div className="flex items-center">
                              <div>
                                <div className="text-sm leading-5 text-gray-800">
                                  {product.id}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap border-b text-green-900 border-gray-500 text-sm leading-5">
                            {product.products.name}
                          </td>

                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                            <div className="text-sm leading-5 text-green-900">
                              {product.products.packaging.weight}{" "}
                              {product.products.packaging.unit.unit}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                            <div className="text-sm leading-5 text-green-900">
                              {calculateTons(
                                product.products.packaging.weight,
                                product.quantity,
                                product.products.packaging.unit.unit
                              )}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                            <div className="text-sm leading-5 text-green-900">
                              {product.currency} {product.price}
                            </div>
                          </td>
                          <td className="px-3 py-4 whitespace-no-wrap border-b text-center border-gray-500">
                            <div className="text-sm leading-5 text-green-900">
                              {product.quantity}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                            <div className="text-sm leading-5 text-green-900">
                              {store.company.name}
                            </div>
                          </td>

                          {/* <td className="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-500 text-sm leading-5">
                          <button
                            // onClick={() => {
                            //   setSelected(company);
                            // }}
                            className="px-5 py-2 border-green-600 border text-green-600 rounded transition duration-300 hover:bg-blue-700 hover:text-white focus:outline-none"
                          >
                            View Details
                          </button>
                        </td> */}

                          {/* <td className="px-6 py-4 whitespace-no-wrap border-b text-green-900 border-gray-500 text-sm leading-5">
                          {product.supported_products.category.name}
                        </td> */}
                          {/* <td className="px-6 py-4 whitespace-no-wrap border-b text-green-900 border-gray-500 text-sm leading-5"> */}
                          {/* {`${product.weight} ${product.unit}`} */}
                          {/* </td> */}
                          {/* <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500 text-green-900 text-sm leading-5"> */}
                          {/* {moment(product.created_at).format("MM/DD/YYYY")} */}
                          {/* </td> */}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <div className="sm:flex-1 sm:flex sm:items-center sm:justify-end mt-4 work-sans">
                {/* <div>
                <p className="text-sm leading-5 text-green-700">
                  Showing
                  <span className="font-medium"> 1 </span>
                  to
                  <span className="font-medium"> 200 </span>
                  of
                  <span className="font-medium"> 2000 </span>
                  results
                </p>
              </div> */}
                <div>
                  <nav className="relative z-0 inline-flex shadow-sm">
                    <div>
                      <a
                        href="#"
                        className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
                        aria-label="Previous"
                      >
                        <svg
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                    </div>
                    <div>
                      <a
                        href="#"
                        className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-green-700 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-tertiary active:text-gray-700 transition ease-in-out duration-150 hover:bg-tertiary"
                      >
                        1
                      </a>
                      <a
                        href="#"
                        className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-green-600 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-tertiary active:text-gray-700 transition ease-in-out duration-150 hover:bg-tertiary"
                      >
                        2
                      </a>
                      <a
                        href="#"
                        className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-green-600 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-tertiary active:text-gray-700 transition ease-in-out duration-150 hover:bg-tertiary"
                      >
                        3
                      </a>
                    </div>
                    <div v-if="pagination.current_page < pagination.last_page">
                      <a
                        href="#"
                        className="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
                        aria-label="Next"
                      >
                        <svg
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
}
