import React, { useEffect, useState } from "react";
import image from "../assets/AFAP-Logo.png";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { Link } from "react-router-dom";

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: twentyFourHoursInMs,
    },
  },
});

export default function Navbar() {
  const [country, setCountry] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    function getCountry() {
      const userString = sessionStorage.getItem("country");
      const userCountry = JSON.parse(userString);
      setCountry(userCountry);
      return userCountry;
    }
    getCountry();
  }, []);

  return (
    <div className="">
      <header className="w-10/12 px-16 2xl:px-0  bg-gray-100 p-4 flex justify-end items-center fixed z-10">
        <div className="w-5 h-5  cursor-pointer">
          <QueryClientProvider client={queryClient}>
            {country && <FlagData country={country} />}
          </QueryClientProvider>
        </div>
        {/* <div className="flex ml-2 items-center">
          <div className="relative items-center">
            <button
              className="dropdown-menu-logout focus:outline-none focus:border-white block"
              onClick={() => setShowDropdown(!showDropdown)}
            >
              <i className="fas fa-cog text-gray-600"></i>
            </button>
            <div
              className={
                showDropdown
                  ? "block dropdown-item-logout right-0 mt-2 py-2 w-64 bg-white absolute border-2 border-gray-200 rounded-lg shadow-lg"
                  : "hidden"
              }
            >
              <Link
                className="block px-6 py-2 hover:bg-gray-200"
                to="/settings/country_configuration"
              >
                Country Configuration
              </Link>
            </div>
          </div>
        </div> */}
      </header>
    </div>
  );
}

function FlagData({ country }) {
  const getFlagAPI = async () => {
    if (country) {
      const res = await fetch(
        `https://restcountries.com/v3.1/name/${country.name}?fullText=true`
      );
      return res.json();
    }
  };
  const { data, error, isLoading } = useQuery("getFlag", getFlagAPI);
  if (isLoading) {
    return null;
  }
  if (error) {
    console.log("An error has occurred: " + error.message);
    return null;
  }
  return (
    <div className="w-full h-full">
      <img className="w-full h-full " src={data[0].flags.svg} alt="flag" />
    </div>
  );
}
